import { Text } from "components";

const Component = ({ msg }) => {
    return(
      <div className='flex justify-center items-center mt-20 w-[100%]'>
          <Text
          className="text-hint text-center w-[auto]"
          variant="body2"
        >
          {msg || "No Data Found"}
        </Text>
      </div>
    )
  }

  export default Component;