import React from "react";
import { Link } from "react-router-dom";
const Home = () => {
  return (
    <div className="dhiwise-navigation">
      <h1>Homepage</h1>
      <p className="headline">
        This project was generated By{" "}
        <a href="https://www.dhiwise.com">Dhiwise</a>. Quickly use below links
        to navigate through all pages.
      </p>
      <ul>
        <li>
          <Link to="/">SignupOne</Link>
        </li>
        <li>
          <Link to="/myaccountmydetailsnine">MyAccountMyDetailsNine</Link>
        </li>
        <li>
          <Link to="/signupeleven">SignupEleven</Link>
        </li>
        <li>
          <Link to="/otpfour">OTPFour</Link>
        </li>
        <li>
          <Link to="/myaccountpassword">MyAccountPassword</Link>
        </li>
        <li>
          <Link to="/selectplansone">SelectPlansOne</Link>
        </li>
        <li>
          <Link to="/selectplans">SelectPlans</Link>
        </li>
        <li>
          <Link to="/paymentdetailseleven">PaymentDetailsEleven</Link>
        </li>
        <li>
          <Link to="/myaccountbillingone">MyAccountbillingOne</Link>
        </li>
        <li>
          <Link to="/myaccountbillingtwo">MyAccountbillingTwo</Link>
        </li>
        <li>
          <Link to="/editcarddetails">EditCardDetails</Link>
        </li>
        <li>
          <Link to="/allfeaturesandbenefitsone">AllfeaturesandbenefitsOne</Link>
        </li>
        <li>
          <Link to="/allfeaturesandbenefits">Allfeaturesandbenefits</Link>
        </li>
        <li>
          <Link to="/billingaddressten">BillingAddressTen</Link>
        </li>
        <li>
          <Link to="/addcarddetailsnineteen">AddCardDetailsNineteen</Link>
        </li>
        <li>
          <Link to="/settings">SettingsOne</Link>
        </li>
        <li>
          <Link to="/settingsthree">SettingsThree</Link>
        </li>
        <li>
          <Link to="/loginfour">LoginFour</Link>
        </li>
        <li>
          <Link to="/logineight">LoginEight</Link>
        </li>
        <li>
          <Link to="/loginsix">LoginSix</Link>
        </li>
        <li>
          <Link to="/otpone">OTPOne</Link>
        </li>
        <li>
          <Link to="/modellibrary">ModelLibrary</Link>
        </li>
        <li>
          <Link to="/modellibrarysearchactive">ModelLibrarysearchactive</Link>
        </li>
        <li>
          <Link to="/modellibrarysearchresult">ModelLibrarysearchresult</Link>
        </li>
        <li>
          <Link to="/modellibrarynotfoundone">ModelLibrarynotfoundOne</Link>
        </li>
       
        <li>
          <Link to="/yourmodels">YourModels</Link>
        </li>
       
        <li>
          <Link to="/modellibraryone">ModelLibraryOne</Link>
        </li>
        <li>
          <Link to="/our-home">Home1</Link>
        </li>
        <li>
          <Link to="/frontend-select-model">SelectModel</Link>
        </li>
        <li>
          <Link to="/frontend-select-techstack">FETechStack</Link>
        </li>
        <li>
          <Link to="/frontend-select-template">FETemplate</Link>
        </li>
        <li>
          <Link to="/fetemplateopen">FETemplateopen</Link>
        </li>
        <li>
          <Link to="/fetemplateadded">FETemplateadded</Link>
        </li>
        <li>
          <Link to="/fetemplatehover">FETemplatehover</Link>
        </li>
        <li>
          <Link to="/backend-select-capabilities">CapabilitiesOne</Link>
        </li>
        <li>
          <Link to="/capabilitiesselected">Capabilitiesselected</Link>
        </li>
        <li>
          <Link to="/basicdetailstwentyfour">BasicDetailsTwentyFour</Link>
        </li>
        <li>
          <Link to="/stepsthirteen">StepsThirteen</Link>
        </li>
        <li>
          <Link to="/stepstwentysix">StepsTwentySix</Link>
        </li>
        <li>
          <Link to="/stepstwentyseven">StepsTwentySeven</Link>
        </li>
        <li>
          <Link to="/stepstwentyeight">StepsTwentyEight</Link>
        </li>
        <li>
          <Link to="/stepstwentynine">StepsTwentyNine</Link>
        </li>
        <li>
          <Link to="/stepsthirty">StepsThirty</Link>
        </li>
        <li>
          <Link to="/stepsthirtyone">StepsThirtyOne</Link>
        </li>
        <li>
          <Link to="/codegeneratedone">CodeGeneratedOne</Link>
        </li>
        <li>
          <Link to="/codeone">CodeOne</Link>
        </li>
        <li>
          <Link to="/forgotpasswordone">ForgotPasswordOne</Link>
        </li>
        <li>
          <Link to="/forgotpasswordtwo">ForgotPasswordTwo</Link>
        </li>
        <li>
          <Link to="/forgotpasswordthree">ForgotPasswordThree</Link>
        </li>
        <li>
          <Link to="/forgotpasswordfour">ForgotPasswordFour</Link>
        </li>
        <li>
          <Link to="/forgotpasswordfive">ForgotPasswordFive</Link>
        </li>
        <li>
          <Link to="/backend-select-model">SelectModelTwo</Link>
        </li>
        <li>
          <Link to="/backend-select-techstack">BackEnd</Link>
        </li>
        <li>
          <Link to="/backend-select-db">Database</Link>
        </li>
        <li>
          <Link to="/frontend-select-capabilities">Capabilities</Link>
        </li>
        <li>
          <Link to="/capabilitiesselectedone">CapabilitiesselectedOne</Link>
        </li>
        <li>
          <Link to="/basicdetailstwentyseven">BasicDetailsTwentySeven</Link>
        </li>
        <li>
          <Link to="/basicdetailstwentytwo">BasicDetailsTwentyTwo</Link>
        </li>
        <li>
          <Link to="/basicdetailstwentythree">BasicDetailsTwentyThree</Link>
        </li>
        <li>
          <Link to="/basicdetailstwentyone">BasicDetailsTwentyOne</Link>
        </li>
        <li>
          <Link to="/basicdetailstwentyeight">BasicDetailsTwentyEight</Link>
        </li>
        <li>
          <Link to="/basicdetailstwentysix">BasicDetailsTwentySix</Link>
        </li>
        <li>
          <Link to="/basicdetailstwentyfive">BasicDetailsTwentyFive</Link>
        </li>
        <li>
          <Link to="/basicdetailsthirtyone">BasicDetailsThirtyOne</Link>
        </li>
        <li>
          <Link to="/basicdetailsthirtysix">BasicDetailsThirtySix</Link>
        </li>
        <li>
          <Link to="/stepsfortythree">StepsFortyThree</Link>
        </li>
        <li>
          <Link to="/stepsfortyfour">StepsFortyFour</Link>
        </li>
        <li>
          <Link to="/stepsfortyfive">StepsFortyFive</Link>
        </li>
        <li>
          <Link to="/stepsfortysix">StepsFortySix</Link>
        </li>
        <li>
          <Link to="/stepsfortyseven">StepsFortySeven</Link>
        </li>
        <li>
          <Link to="/stepsfortyeight">StepsFortyEight</Link>
        </li>
        <li>
          <Link to="/stepsfortynine">StepsFortyNine</Link>
        </li>
        <li>
          <Link to="/frame53226">Frame53226</Link>
        </li>
        <li>
          <Link to="/codegeneratedtwo">CodeGeneratedTwo</Link>
        </li>
        <li>
          <Link to="/codetwo">CodeTwo</Link>
        </li>
        <li>
          <Link to="/databaselockedthree">DatabaselockedThree</Link>
        </li>
        <li>
          <Link to="/databaselockedtwo">DatabaselockedTwo</Link>
        </li>
        <li>
          <Link to="/resetpasswordone">ResetPasswordOne</Link>
        </li>
        <li>
          <Link to="/resetpasswordtwo">ResetPasswordTwo</Link>
        </li>
        <li>
          <Link to="/resetpasswordthree">ResetPasswordThree</Link>
        </li>
        <li>
          <Link to="/resetpasswordfour">ResetPasswordFour</Link>
        </li>
        <li>
          <Link to="/resetpasswordfive">ResetPasswordFive</Link>
        </li>
        <li>
          <Link to="/resetpasswordnine">ResetPasswordNine</Link>
        </li>
        <li>
          <Link to="/resetpasswordten">ResetPasswordTen</Link>
        </li>
        <li>
          <Link to="/resetpasswordeight">ResetPasswordEight</Link>
        </li>
        <li>
          <Link to="/both-select-model">SelectModelFour</Link>
        </li>
        <li>
          <Link to="/both-select-techstack">FETechStackOne</Link>
        </li>
        <li>
          <Link to="/fetechstackselectedone">FETechStackselectedOne</Link>
        </li>
        <li>
          <Link to="/both-select-template">FETemplateOne</Link>
        </li>
        <li>
          <Link to="/fetemplateopenone">FETemplateopenOne</Link>
        </li>
        <li>
          <Link to="/fetemplateaddedone">FETemplateaddedOne</Link>
        </li>
        <li>
          <Link to="/fetemplatehoverone">FETemplatehoverOne</Link>
        </li>
        <li>
          <Link to="/both-select-backend">BackEndOne</Link>
        </li>
        <li>
          <Link to="/databasetwo">DatabaseTwo</Link>
        </li>
        <li>
          <Link to="/both-select-db">DatabaseThree</Link>
        </li>
        <li>
          <Link to="/both-select-capabilities">CapabilitiesTwo</Link>
        </li>
        <li>
          <Link to="/capabilitiesselectedtwo">CapabilitiesselectedTwo</Link>
        </li>
        <li>
          <Link to="/basicdetailsthirtyfive">BasicDetailsThirtyFive</Link>
        </li>
        <li>
          <Link to="/basicdetailsthirtyeight">BasicDetailsThirtyEight</Link>
        </li>
        <li>
          <Link to="/basicdetailsthirtythree">BasicDetailsThirtyThree</Link>
        </li>
        <li>
          <Link to="/basicdetailsthirtynine">BasicDetailsThirtyNine</Link>
        </li>
        <li>
          <Link to="/backend-basic-details">BasicDetailsSeven</Link>
        </li>
        <li>
          <Link to="/basicdetailstwelve">BasicDetailsTwelve</Link>
        </li>
        <li>
          <Link to="/frontend-basic-details">BasicDetailsFour</Link>
        </li>
        <li>
          <Link to="/both-review">Review</Link>
        </li>
        <li>
          <Link to="/generating-frontend-code">StepsOne</Link>
        </li>
        <li>
          <Link to="/stepstwo">StepsTwo</Link>
        </li>
        <li>
          <Link to="/stepsthree">StepsThree</Link>
        </li>
        <li>
          <Link to="/stepsfour">StepsFour</Link>
        </li>
        <li>
          <Link to="/stepsfive">StepsFive</Link>
        </li>
        <li>
          <Link to="/stepsseven">StepsSeven</Link>
        </li>
        <li>
          <Link to="/stepseight">StepsEight</Link>
        </li>
        <li>
          <Link to="/stepsnine">StepsNine</Link>
        </li>
        <li>
          <Link to="/stepsten">StepsTen</Link>
        </li>
        <li>
          <Link to="/stepseleven">StepsEleven</Link>
        </li>
        <li>
          <Link to="/stepstwelve">StepsTwelve</Link>
        </li>
        <li>
          <Link to="/codegenerated">CodeGenerated</Link>
        </li>
        <li>
          <Link to="/code">Code</Link>
        </li>
        <li>
          <Link to="/yourmodelstwo">YourModelsTwo</Link>
        </li>
        <li>
          <Link to="/stepssix">StepsSix</Link>
        </li>
        <li>
          <Link to="/databaselocked">Databaselocked</Link>
        </li>
        <li>
          <Link to="/whats-new">WhatsNew</Link>
        </li>
        <li>
          <Link to="/help-and-support">HelpandSupportOne</Link>
        </li>
        <li>
          <Link to="/faqs">FAQs</Link>
        </li>
        <li>
          <Link to="/error">Error</Link>
        </li>
        <li>
          <Link to="/error1">Error1</Link>
        </li>
      </ul>
    </div>
  );
};
export default Home;
