import React from "react";
import "./styles/color.css";
import "./styles/font.css";
import ReactDOM from "react-dom";
import App from "./App";
import "./styles/index.css";
import "./styles/tailwind.css";
import { Auth0Provider } from "@auth0/auth0-react";
import { Provider } from "react-redux";
import { store } from "store/store";
import { BrowserRouter } from "react-router-dom";
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

posthog.init('phc_iF37YV4Mp2hrGjB0OzVpKjqGa28kFdotY8pQlC8G1Sl', {
   api_host: 'https://us.i.posthog.com' 
})

ReactDOM.render(
  <React.StrictMode>
     <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: window.location.origin
    }}
    >
    <Provider store={store}>
      <PostHogProvider client={posthog}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PostHogProvider>
    </Provider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
